/* 
  disable horizontal scroll because of the Dropdown component
*/
body {
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.max-width-200 {
  max-width: 200px;
}

.width-95 {
  width: 95px;
}

.min-width-200 {
  min-width: 200px;
}

.width-50 {
  width: 50px;
}

.width-200 {
  width: 200px;
}

.min-width-45 {
  min-width: 45%;
}

.filtered-column_bg{
  background: #FFFFFF;
  border: 2px solid #525F7F;
}

.danger_text{
  color: #f5365c;
}

.word-wrap-normal {
  word-wrap: normal;
}

.pc-breadcrumb-item {
  padding-left: 0.5rem;

  &::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #adb5bd;
    content: "-";
  }
}

.pc-portin-back-button {
  white-space: nowrap;
  font-weight: bold;
}

.pc-ad-container {
  justify-content: center;
  width: 100%;
}

.pc-ad-container img {
  max-width: 100%;
  border: 0;
}

.hs-cta-img {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.hubspot-banner {
  flex: 1 1 auto;
  max-width: 100%;
}

.hubspot-banner img {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.pc-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.pc-logo img {
  width: 100%;
}

.pc-table-header {
  height: 50px;
  display: flex;
  flex-direction: column;
}

.add-supplement-modal-section {
  flex-basis: 25%;
  flex-grow: 1;
}

.text-provision-confirmation {
  font-size: 0.875rem;
}

.custom-underline {
  position: relative;
  margin: 0px;
}

.custom-underline::after {
  content: '';
  position: absolute;
  width: 120px;
  bottom: 0px;
  left: 0;
  border: 2px solid #2E5599;
}

.notification-scroll {
  height: 500px;
  overflow-y: scroll;
}

@media only screen and (min-width: 1500px) {
  .notification-scroll {
    height: 700px;
  }
}

.custom-underline-reserved::after{
  bottom: -5px;
}

.tn-list {
  list-style-type: none;
  height: 180px;
  overflow-y: auto;
  background-color: #e9ecef;
  opacity: 1;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.625rem 0.75rem;
  line-height: 1.5;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-basis-16 {
  flex-basis: 16%;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-33 {
  flex-basis: 33%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-95 {
  flex-basis: 95%;
}

.pc-timeline-container {
  overflow-y: scroll;
}

.pc-last-form-input {
  margin-bottom: 0 !important;
}

.pc-inline-loading-indicator {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.pc-no-shadow {
  box-shadow: none;
}

.pc-navbar-img {
  height: 71px !important;
}

.pc-sidebar-img {
  margin-bottom: -3.5rem;
  margin-top: -2rem;
  max-height: 6rem !important;
}

.mix-sidebar-img {
  max-height: 5rem !important;
}

.numhub-sidebar-logo {
  width: 100%;
  max-height: 34rem !important;
}

.numhub-sidebar {
  margin-top: auto;
}

.bg-lcolor {
  background-color: #F0F3F7 !important;
}

.bg-lblue {
  background-color: #1A7399 !important;
}

.bg-lpurple {
  background-color: rgba(132, 76, 185, 0.15);
}

.spid-bg-dblue {
  background-color: #2E5599 !important;
}

.spid-bg-lgreen {
  background-color: #B3E672 !important;
}

.lgreen-spid-cell {
  color: #151022;
}

a.spid-bg-dblue:hover, a.spid-bg-dblue:focus,
button.spid-bg-dblue:hover,
button.spid-bg-dblue:focus {
  background-color: #223f72 !important; }

a.spid-bg-lgreen:hover, a.spid-bg-lgreen:focus,
button.spid-bg-lgreen:hover,
button.spid-bg-lgreen:focus {
  background-color: #a0e83b !important; }

.lblue-color {
  color: #1A7399 !important;
}

.dblue-color {
  color: #2E5599;
}

.purple-color {
  color: #844CB9;
}

.pgreen-color {
  color: #B3E672;
}

.text-crow {
  color: #151022 !important;
}

.text-tn-disabled {
  color: #8898aa !important;
}

.text-tn-reason-disabled {
  color: #35373a !important;
}

.table-text-dark {
  color: #212529 !important;
}

.table-search::placeholder {
  color: #151022 !important;
}

.create-btn {
  height: 46px;
}

.thead-light-blue {
  background-color: rgba(26, 115, 153, 0.15);
}

.table-filters-dark {
  color: #212529 !important;
}

.table-filters-dark::placeholder {
  color: #212529 !important;
}

.pc-navbar-new-img {
  height: 176px !important;
  margin: -42px;
  margin-left: -14px;
  margin-bottom: -58px;
}

.pc-navbar-image {
  height: 25px;

  &:hover {
    opacity: 0.5;
  }
}

.pc-display-block {
  display: block;
}

.pc-white-space-normal {
  white-space: normal;
}

.pc-number-explorer-bg {
  color: $pc-number-explorer-color;
}

.pc-network-explorer-bg {
  color: $pc-network-explorer-color;
}

.footer-font-size {
  font-size: 14px;
}

.customer-list {
  border: 1px solid #ddd; /* Border around the entire list */
  border-radius: 4px; /* Optional: Add rounded corners */
  overflow: hidden; /* Ensure the border is continuous */
}

.customer-list .customer-row {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ddd; /* Border between rows */
  gap: 5px; /* Space between checkbox and name */
}

.customer-list .customer-header {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 5px;
  background-color: #f7f7f7;
  font-weight: bold;
  border-bottom: 1px solid #ddd; /* Border for header */
}

.customer-list input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.pc-soa-order-bg {
  color: $pc-soa-order-color;
}

.pc-input-group {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  -webkit-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.pc-list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  max-height: inherit;
  overflow-y: scroll;
  height: 100px;
}

.pc-list-group-item {
  position: relative;
  display: block;
  margin-bottom: -1px;
  background-color: #fff;
}

.pc-hide-button {
  float: right;
  visibility: hidden;
  cursor: pointer;
}

.pc-text-link {
  text-decoration: underline;
  cursor: pointer;
  color: $link-color;
}

.pc-success-button {
  color: #2dce89;
  background-color: white;
}

.pc-list-group-item:hover .pc-hide-button {
  visibility: visible;
}

.pc-search-container {
  display: flex;
}

.pc-create-dropdown {
  display: flex;
  align-items: flex-end;
}

.pc-search {
  width: 400px;
  font-size: 0.875rem;
  display: inline-block;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.order-status-tooltip {
  font-size: 0.875rem;
}

.pc-flexwrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.pc-btn {
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
  color: #fff;
  border-color: #172b4d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.pc-nav-border-bottom {
  border-bottom: 1px solid $pc-background-color !important;
}

.pc-bg {
  color: $pc-background-color;
}

.pc-hidden-lg {
  display: none;
}

#footer-main {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.pc-accept {
  border-color: $pc-spring-green;
}

.pc-reject {
  border-color: $pc-torch-red;
}

.pc-active {
  border-color: $pc-dodger-blue;
}

.pc-invalid-input {
  border-color: $orange;
}

.pc-invalid-input:focus {
  border-color: $orange;
}

.focused {
  .pc-invalid-input:focus {
    border-color: $orange;
  }
}

.pc-default {
  border-color: $pc-light-gray;
}

.pc-close-modal {
  z-index: 1;
}

.pc-drag-drop-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.pc-btn-card {
  display: block;
}

.pc-card-header {
  min-height: 67px;
}

.pc-column-without-header {
  margin-top: 21px;
}

.rdtCounter .rdtCount {
  padding: 6px !important;
}

table .left-datepicker .rdtPicker {
  right: 0px;
  left: auto;
}

.top-datepicker .rdtPicker {
  bottom: 70px;
  top: auto;
}

table .rdtPicker {
  max-width: 300px !important;
}

table .rdtPicker table {
  max-width: 300px !important;
  table-layout: fixed;
}

table .rdtPicker table thead tr th {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

table .rdtPicker table tbody tr td {
  border-color: $white !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
  vertical-align: middle !important;
  text-transform: none !important;
  border-top: none;
}

table .rdtPicker table thead th {
  text-transform: none;
  background-color: white !important;
}

.table-settings-modal-content {
  max-width: 400px !important;
}

.table-settings-modal-body {
  padding: 0rem 1rem 1rem 1rem !important;
}

.table-settings-modal-customviewsettings-spid-filter-list {
  overflow-y: scroll;
  height: 316px;
  width: 377px;
  padding-left: 31px;
}

.table-settings-modal-customviewsettings-tab {
  width: 169px;
  height: 32px;
  line-height: 0.5rem;
}

label[for="customCheckcsrRequest"] {
  width: 100%;
}

.pc-btn-audit {
  background-color: #5e72e4 !important;
  color: white;
}

.pc-btn-logs {
  background-color: #2e5599;
  color: white;
  width: 33px;
  height: 33px;
  display: grid;
  justify-content: center;
}

.pc-min-width-375 {
  min-width: 375px;
}

.pc-min-width-550 {
  min-width: 550px;
}

.h-180 {
  height: 180px !important;
}

.pc-flex-end {
  align-items: flex-end;
}

.toggleLoader {
  margin-left: 27px;
  margin-top: -42px;
}

//inline edit
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
}

.inline-text .fa-edit {
  cursor: pointer;
  font-size: initial;
}

.inline-text_input--active {
  border-bottom: 1px solid #666666;
  text-align: left;
  width: 300px;
}

.pc-overflow-x-auto {
  overflow: auto !important;
}

.padding-horizontal-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.height-810 {
  height: 810px !important;
}

.pc-task-description {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  max-height: 4.5em;
  overflow-y: hidden;
  position: relative;
  font-size: 13px;

  &::before {
    position: absolute;
    content: "\02026";
    bottom: 0.7px;
    right: 0;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
  }
}

.pc-task-description-show-more {
  text-decoration: underline;
}

.pc-account-details-input {
  flex-grow: 1;
  white-space: nowrap;
  @media only screen and (min-width: 1600px) {
    flex-basis: calc(16% - 1rem);
  }
}

.pc-service-address-input-label {
  //if media query is met, it will strip whitespace from label
  @media only screen and (min-width: 1448px) {
    white-space: nowrap;
  }

  //if media query met it, it will set all the columns' labels min-height to a max of 3 lines.
  //using the following so all the column labels in the row have the same height for the moment when bootstrap's grid starts breaking the labels content
  //into multiple lines

  @media only screen and (min-width: 768px) and (max-width: 1447px) {
    min-height: 4.2em;
  }
}

.pc-no-resize {
  resize: none;
}

.margin-top-29 {
  margin-top: 29px;
}

.pc-required-label:after {
  content: " *";
  color: #fb6340;
}

.pc-flex-button-container {
  display: flex;
  align-items: center;
  :not(.align-left):first-child:before {
    content: "";
  }
  :not(.align-left):first-child {
    margin-left: auto;
  }
  .align-left {
    margin-right: auto;
  }
}


// clear default tabs/nav-pills bg-color and font-color style
#account-tabs.nav-pills .nav-link{
  background-color:unset;
  color: unset;
}

// efx for a selected tab
.account-tab-active-nav-item {
  background-color:white;
  border-bottom: 2px solid $primary;
}
.account-tab-active-nav-link {
  font-weight: bold !important;
  color: $primary !important;
}

.account-tab-active-nav-item-light {
  background-color:white;
  border-bottom: 2px solid #1A7399;
}
.account-tab-active-nav-link-light {
  font-weight: bold !important;
  color: #1A7399 !important;
}

//efx for an 'un-selected tab'
#account-tabs.nav-pills{
  background-color:#EFEFF2;    
  color: rgba($primary, .5) !important;
}

//efx when focusing/hovering over tab
#account-tabs.nav-pills > li:hover{
  background-color:#E3E3E3;    
  color: rgba($primary, 1) !important;
}

.pc-order-status-warning {
  color: #fbc940;
}

.pc-npac-maintenance-warning {
  margin-top: 0px;
  margin-left: 0px;
}

.pc-npac-maintenance-modal-warning {
  margin-top: 10px;
  margin-left: 15px;
}

.pc-npac-maintenance-warning > a,
.pc-npac-maintenance-modal-warning > a {
  text-decoration: underline;
  color: white !important;
}

.pc-react-table-custom-view-settings {
  max-height: 300px !important;
}

.pc-react-table-custom-view-settings-tab-content {
  height: auto;
  width: 364px;
  padding-left: 12px;
}

.text-center {
  text-align: center;
}

.filter-align {
  display: inline-block;
  width: auto;
  padding: 0;
}

.cursor-auto {
  cursor: auto !important;
}

.list-items-into-columns {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  column-gap: 20px;
}

.shortcut-item small {
  font-size: 0.7rem !important;
}

.info-tooltip-arrow::before {
  border-right-color: $info !important;
}

.Toastify__toast-container--top-center {
  width: unset !important;
  margin-left: -18vw !important;
}

.pc-manage-organization-account-type-identifier {
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.pc-manage-organization-account-type-identifier-parent {
  color: #527f5c;
}

.pc-manage-organization-account-type-identifier-child {
  color: #fb6340;
}
.pc-manage-organization-account-type-identifier-stand-alone {
  color: #2e5599;
}

.pc-manage-organization-associated-spids {
  white-space: break-spaces;
}

.pc-manage-organization-row-expander-inactive {
  color: #6c7793;
}

.pc-manage-organization-row-expander-active {
  color: #6c7793;
  transform: rotate(90deg);
}

.print-only {
  display: none;

  @media print {
    display: block;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.select2.select2-container {
  width: 100% !important;
  min-width: 220px;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
}
.btn-text-switc-off {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.switch-wrapper>input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
.switch-wrapper>input[type="checkbox"]+.switch {
  transform: translateX(5px);
}
.switch-wrapper>input[type="checkbox"]:checked+.switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper.large>input[type="checkbox"]:checked+.switch {
  transform: translateX(50%) translateX(-35px);
}
.switch-wrapper>input[type="checkbox"]:disabled+.switch {
  background: #ccc;
}
.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.switch-wrapper.large {
  height: 35px;
  width: 65px;
}
.switch-wrapper>.switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper>.switch>.switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  top: 4px;
  width: 14px;
  z-index: 3;
}
.switch-wrapper.large>.switch>.switch-handle {
  height: 27px;
  width: 30px;
}
.switch-label {
  float: left;
  line-height: 2.5rem;
  margin-left: 10px;
}
.switch-wrapper.large+.switch-label {
  line-height: 4rem;
  margin-left: 15px;
}

.switch {
  font-size: 13px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  margin-bottom: 0px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: #ece9e9;
  border-radius: 50px;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  right: 0.3em;
  bottom: 0.3em;
  transform: translateX(150%);
  background-color: #59d102;
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.slider:after {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  left: 0.3em;
  bottom: 0.3em;
  background-color: #cccccc;
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #59d102;
}

.switch input:checked + .slider:before {
  transform: translateY(0);
}

.switch input:checked + .slider::after {
  transform: translateX(-150%);
}

@media only screen and (max-width: 2990px) {
  .workqueue-count-card-container {
    flex: 0 1 calc(20% - 8px);
  }
  .pc-count-card {
    min-height: 116px;
  }
}
@media only screen and (max-width: 1950px) {
  .workqueue-count-card-container {
    flex: 0 1 calc(25% - 8px);
  }
  .pc-count-card {
    min-height: 116px;
  }
}
@media only screen and (max-width: 1600px) {
  .workqueue-count-card-container {
    flex: 0 1 calc(33% - 8px);
  }
}
@media only screen and (max-width: 1088px) {
  .workqueue-count-card-container {
    flex: 0 1 calc(50% - 8px);
  }
}

@media only screen and (max-width: 2990px) {
  .pc-count-card-container {
    flex: 0 1 calc(20% - 8px);
  }
  .pc-count-card {
    min-height: 116px;
  }
}
@media only screen and (max-width: 1368px) {
  .pc-count-card-container {
    flex: 0 1 calc(33% - 8px);
  }
}
@media only screen and (max-width: 1088px) {
  .pc-count-card-container {
    flex: 0 1 calc(50% - 8px);
  }
}
@media only screen and (max-width: 991px) {
  .pc-hidden-lg {
    display: block;
  }
  .pc-hidden-sm {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .pc-portin-back-button {
    white-space: normal;
  }
}
@media only screen and (max-width: 501px) {
  .pc-count-card-container {
    flex: 0 1 calc(100% - 8px);
  }
}
@media only screen and (max-width: 460px) {
  .pc-navbar-img {
    height: 20px !important;
  }
}
@media (min-width: 1500px) {
  .table-responsive {
    overflow-x: auto;
  }
}
@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1000px;
  }
}
@media (min-width: 992px) {
  .pc-modal-xlg {
    max-width: 1200px;
  }
  .modal-xl {
    max-width: 800px;
  }
}

.tpp-status {
  background-color: #1A7399;
  width: 336px !important;
  height: 80px !important;
}

.tpp-button-light {
  background-color: #BACBE9;
}

.bg-lwhite {
  background-color: #fff;
}

.lblue-button-border {
  background-color: #fff;
  border: 1px solid #1A7399;
}

.no-border {
  border: 0 !important; 
}

.btn-outline-lblue {
  color: #1A7399;
  background-color: transparent;
  background-image: none;
  border-color: #1A7399; }
  .btn-outline-lblue:hover {
    color: #fff;
    background-color: #1A7399;
    border-color: #1A7399; }
  .btn-outline-lblue:focus, .btn-outline-lblue.focus {
    box-shadow: 0 0 0 0 rgba(23, 115, 153, 0.5); }
  .btn-outline-lblue.disabled, .btn-outline-lblue:disabled {
    color: #1A7399;
    background-color: transparent; }
  .btn-outline-lblue:not(:disabled):not(.disabled):active, .btn-outline-lblue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lblue.dropdown-toggle {
    color: #fff;
    background-color: #1A7399;
    border-color: #1A7399; }
    .btn-outline-lblue:not(:disabled):not(.disabled):active:focus, .btn-outline-lblue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-lblue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 115, 153, 0.5); }

.trade-action-icon {
  height: 18.75px;
  width: 24px;
}

.accept-trade-icon {
  height: 24px;
  width: 24px;
  margin-right: 6px;
}

.popover-body {
  background-color: black;
  color: white;
}

.order-actions-dropdown {
  color: #97AACC !important;
}

.order-actions-dropdown:hover,
.order-actions-dropdown:focus,
.order-actions-dropdown:active {
  color: #000000 !important;
}

.order-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  outline: none;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  cursor: pointer;
}

.order-checkbox:checked {
  background-color: #2e5599;
  border-color: #2e5599;
}

.lblue-checkbox:checked {
  background-color: #1a7399;
  border-color: #1a7399;
}

.order-checkbox:checked::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 60% 60%;
  box-sizing: border-box;
  opacity: 1;
}

.selected-row {
  background-color: #F6F9FC;
}

.breadcrumb-bold {
  font-weight: 600;
}

.bg-dropdown {
  background-color: #fafafa;
}

.notification-circle {
  border-radius: 50% !important;
  position: absolute;
  bottom: -10px;
  left: -10px; 
  background: #8DAADD;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 14px;
}

.notification-text {
  width: 6px;
  height: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #000000;
  vertical-align: middle;
}

.download-template-btn {
  background-color: #BACBE9;
}

.filter-container {
  width: 100%;
  margin-left: 20px;
  margin-bottom: 10px;
}

.no-gutters {
  padding-right: 0;
  padding-left: 0;
}

.icon-filter {
  color: #2e5599;
}

.filter-row {
  margin-left:-7px;
}

.loading-wave {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 5px;
  height: 10px;
  margin: 0 5px;
  background-color: #2e5599;
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

.calendar-position {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.calendar-icon {
  color: #2e5599;
}

.polygon-loader-main {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 20%;
  justify-content: center;
}

.spinner {
  width: 50px;
  height: 50px;
  animation: spinner-y0fdc1 2s infinite ease;
  transform-style: preserve-3d;
 }

 .spinner > div {
  background-color: rgba(0,77,255,0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 2px solid #2e5599;
 }

 .spinner div:nth-of-type(1) {
  transform: translateZ(-22px) rotateY(180deg);
 }

 .spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
 }

 .spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
 }

 .spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
 }

 .spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
 }

 .spinner div:nth-of-type(6) {
  transform: translateZ(22px);
 }

 .icon-font-size {
  font-size: 24px;
 }

 .custom-tooltip-background .tooltip-inner {
  background-color: #1a7399d5 !important; /* Blue background */
  color: white !important; /* White text */
}

/* Tooltip arrow color */
.custom-tooltip-background .tooltip .arrow::before {
  border-bottom-color: #1a7399d5;
  border-top-color: #1a7399d5;
}

 @keyframes spinner-y0fdc1 {
  0% {
   transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
   transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
   transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
 }

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 17px;
  }

  100% {
    height: 10px;
  }
}
