.inventory_navbar {
  border-radius: 8px;
  margin-bottom: 15px;
  align-items: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1rem;
}

.inventory_navbar_dbg {
  background-color: #474747;
}

.navbar_items {
  display: flex;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0px;
}

.navbar_items_link {
  padding: 22px;
  color: white;
}

.navbar_items .active {
  color: #4196D2;
}

.navbar_items_light {
  display: flex;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0px;
}

.navbar_items_light .active {
  color: #1A7399;
}

.navbar_items_light_link {
  padding: 22px;
  color: #151022;
}

.navbar_items_link:not(.active):hover {
  color: #2E5599;
}
